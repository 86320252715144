import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import SEO from "../components/seo"
import TeamComponent from "../components/TeamComponent/TeamComponent"
import "../styles/global.scss"
import { unloadRecaptcha } from "../utils/Captcha"

const Team = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <LayoutComponent isEnglish languageSwitchSubpage="team">
      <SEO title="Team" />
      <MenuComponent
        selected={2}
        isEnglish
        submenu={[
          { title: "History", url: "#historie" },
          { title: "Members", url: "#team" },
          { title: "Join us", url: "/en/contact" },
        ]}
      />
      <h2 className="underline-headline a-little-bit-of-margin-top">History</h2>
      <p className="left-side-margin a-little-bit-of-margin-top">
        Barokcity Rollerderby was founded in 2007, as the second rollerderby
        team in Germany. In 2015 the Rollerderby Bundesliga was founded in
        Germany. Furthermore, BCRD joined the international rollerderby
        governing body WFTDA (Womans Flat Track Derby Association{" "}
        <a href="https://wftda.com/" target="_blank" rel="noreferrer nofollow">
          https://wftda.com/
        </a>
        ) and after one year of candidacy was recognized as a full member in
        2016. We regularly provide players for the national teams.
      </p>
      <p className="left-side-margin a-little-bit-of-margin-top">
        BCRD is part of the MTV Ludwigsburg sports club as of 03/29/2019:
        <br />
        The MTV Ludwigsburg is the club with the most members in Ludwigsburg and
        one of the one of the largest sports clubs in Baden-Württemberg. Not
        dedicated to competitive sports, the MTV sports club creates many new
        activities for all age offers for all age groups that enjoy exercise.
      </p>
      <p className="left-side-margin a-little-bit-of-margin-top">
        Homepage:{" "}
        <a
          href="https://mtv-ludwigsburg.de"
          target="_blank"
          rel="noreferrer nofollow"
        >
          https://mtv-ludwigsburg.de
        </a>
      </p>
      <TeamComponent isEnglish />
    </LayoutComponent>
  )
}

export default Team
